var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "my-4 pa-4",
      attrs: { id: "cardBars", height: 450, width: "100%" },
    },
    [
      _c("v-card-title", { attrs: { id: "cardTitle" } }, [
        _vm._v("Por situação"),
      ]),
      _c(
        "v-card-text",
        { staticStyle: { height: "150px" } },
        [
          _c("apexCharts", {
            ref: "realtimeChart",
            staticClass: "apexchart",
            attrs: {
              width: "100%",
              height: "350",
              options: _vm.chartOptions,
              series: _vm.totalForSituation,
              type: "bar",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }