<template>
  <v-card id="cardBars" :height="450" width="100%" class="my-4 pa-4">
    <v-card-title id="cardTitle">Por situação</v-card-title>
    <v-card-text style="height: 150px">
      <apexCharts
        ref="realtimeChart"
        class="apexchart"
        width="100%"
        height="350"
        :options="chartOptions"
        :series="totalForSituation"
        type="bar"
      ></apexCharts>
    </v-card-text>
  </v-card>
</template>

<script>
// import ThemeMixin from '@/mixins/Theme.vue';
let primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--v-primary-base');
import 'dayjs/locale/pt-br';
import { mapGetters } from 'vuex';

export default {
  name: 'TotalForSituation',

  components: {
    ApexCharts: () => import('vue-apexcharts'),
  },

  data() {
    return {
      chartOptions: {
        states: {
          active: {
            filter: {
              type: 'none',
            },
          },

          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },

          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },

        defaultLocale: 'pt-br',
        chart: {
          toolbar: {
            show: false,
          },

          selection: {
            enabled: false,
            type: 'x',
          },
        },

        plotOptions: {
          bar: {
            barHeight: '50%',
            distributed: true,
            horizontal: true,
          },
        },

        colors: ['#00FF99', primaryColor, primaryColor, primaryColor, primaryColor, primaryColor],

        dataLabels: {
          textAnchor: 'start',
          offsetX: 750,
          style: {
            fontSize: '12px',
            fontWeight: 'regular',
            colors: ['#575767'],
          },
        },

        annotations: {
          yaxis: [
            {
              y: 'Pendente de validação',
              fillColor: '#E7E7FA',
              strokeDashArray: 0,
              borderColor: '#E7E7FA',
              opacity: 1,
              offsetX: 0,
              offsetY: 23,
              width: '100%',
            },
            {
              y: 'Conciliada',
              fillColor: '#E7E7FA',
              strokeDashArray: 0,
              borderColor: '#E7E7FA',
              opacity: 1,
              offsetX: 0,
              offsetY: 23,
            },
            {
              y: 'Pendente de aprovação',
              fillColor: '#E7E7FA',
              strokeDashArray: 0,
              borderColor: '#E7E7FA',
              opacity: 1,
              offsetX: 0,
              offsetY: 23,
            },
            {
              y: 'Pendente de parametrização',
              fillColor: '#E7E7FA',
              strokeDashArray: 0,
              borderColor: '#E7E7FA',
              opacity: 1,
              offsetX: 0,
              offsetY: 23,
            },
            {
              y: 'Divergente',
              fillColor: '#E7E7FA',
              strokeDashArray: 0,
              borderColor: '#E7E7FA',
              opacity: 1,
              offsetX: 0,
              offsetY: 23,
            },
          ],
        },

        grid: {
          show: true,
          xaxis: {
            lines: {
              show: false,
            },
          },

          yaxis: {
            lines: {
              show: false,
            },
          },
        },

        legend: {
          show: false,
        },

        xaxis: {
          show: false,
          labels: {
            show: false,
          },

          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
            borderType: 'none',
          },

          tooltip: {
            enabled: false,
          },
        },

        yaxis: {
          show: true,
          labels: {
            align: 'rigth',
            maxWidth: 190,
            show: true,
            style: { fontSize: '12px', cssClass: 'labelGraphic' },
            offsetX: -10,
          },

          axisBorder: {
            show: false,
          },

          axisTicks: {
            show: false,
            borderType: 'none',
          },

          crosshairs: {
            show: false,
          },

          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      totalForSituation: 'esocial/esocialMain/getTotalForSituation',
      situationColors: 'esocial/esocialMain/getSituationColors',
    }),

    totalForColors() {
      return this.totalForSituation[0].data;
    },
  },
};
</script>
<style scoped>
#cardBars {
  border: 1px solid #e7e7fa !important;
  background: #f9fafb !important;
  border-radius: 4px !important;
}
#cardTitle {
  color: var(--v-titleColor-base) !important;
  font-size: 20px;
  font-weight: regular;
}
</style>
